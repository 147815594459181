import Promise from "bluebird"

import React from "react"
import {withTranslation} from "react-i18next"
import {Col, Row} from "reactstrap"
import {compose} from "redux"
import {ButtonSpinner} from "../../../Components/Animations/Button/ButtonSpinner"
import {SearchableDropdown} from "../../../Components/Input"
import Loader from "../../../Components/Loader/Loader"
import {IP4} from "../../../Helper/IP4"
import {Value} from "../../../Helper/Validator"
import {AbortButton, Button, FlexRow, Input, Snackbar} from "@greenbone/cloud-component-library"
import {Subheadline, TextLink} from "../../../StyledComponents/Font/Font"
import {getValidationResult} from "../../../controller/FieldValidator"
import {
    CustomValidationMethod,
    IsInteger,
    IsNotEmptyList,
    IsNotEmptyString,
    IsOptionalString
} from "../../../controller/FormValidators"
import {CredentialsRestApiClient} from "../../../services/apiClients/Credentials/CredentialsRestApiClient"
import {RemoteResourceRestApiClient} from "../../../services/apiClients/RemoteResource/RemoteResourceRestApiClient"
import {TargetRestApiClient} from "../../../services/apiClients/Target/TargetRestApiClient"
import {
    BadRequestError,
    ConflictError,
    ForbiddenError,
    NotAcceptableError,
    ServiceNotAvailableError,
    UnprocessableEntityError
} from "../../../services/Exceptions"
import {TargetFormHostHandler} from "../components/TargetFormHostHandler"
import {SimpleEntityContext} from "../../../services/Context/SimpleEntityContext"
import {IP6} from "@greenbone/cloud-ip-library"
import {TargetFormHeader} from "./TargetFormHeader"
import {IncludedAddressInputArea} from "./IncludedAddressInputArea"
import {ExcludedAddressInputArea} from "./ExcludedAddressInputArea"
import {HostnameInputArea} from "./HostnameInputArea"
import {isStringBlankOrWhitespace} from "@greenbone/cloud-validation-library"
import {TargetFormMode} from "./TargetFormMode"


type Props = {
    isInternal: boolean;
    onClose: EventCallback;
    onSave: EventCallback;
    id: ?string;
    t: any;

}

type State = {
    fields: {
        name: string;
        comment: string;
        includedHosts: Array<string>;
        excludedHosts: Array<string>;
        aliveTestId: ?number;
        portListId: ?number;
        sshCredentialsId: ?string;
        smbCredentialsId: ?string;
        esxiCredentialsId: ?string;
        sshPort: ?string;
    },
    fieldStatus: {
        name: ?boolean;
        comment: ?boolean;
        includedHosts: ?boolean;
        excludedHosts: ?boolean;
        aliveTestId: ?boolean;
        portListId: ?boolean;
        sshCredentialsId: ?boolean;
        smbCredentialsId: ?boolean;
        esxiCredentialsId: ?boolean;
        sshPort: ?boolean;

    };
    portLists: Array<GSPPortList>;
    aliveTests: Array<GSPAliveTest>;
    credentials: Array<GSPCredentialsEntity>;
    isInternal?: boolean;
    isValid: boolean;
    _loading: boolean;
    _saving: boolean;
    _exception: any;
    isExtended: boolean;
}

export class _TargetCreate extends React.Component<Props, State> {
    remoteResourceRestClient: RemoteResourceRestApiClient
    targetRestClient: TargetRestApiClient
    credentialsRestClient: CredentialsRestApiClient
    setStateAsync: Promise
    includedHostsRef = (React: any).createRef()
    excludedHostsRef = (React: any).createRef()
    hostnameRef = React.createRef()
    inputHostValue: string
    exputHostValue: string

    state: State = {
        fields: {
            name: "",
            comment: "",
            includedHosts: [],
            excludedHosts: [],
            aliveTestId: null,
            portListId: null,
            sshCredentialsId: "",
            smbCredentialsId: "",
            esxiCredentialsId: "",
            sshPort: ""
        },
        fieldStatus: {
            name: null,
            comment: null,
            includedHosts: null,
            excludedHosts: null,
            aliveTestId: null,
            portListId: null,
            sshCredentialsId: null,
            smbCredentialsId: null,
            esxiCredentialsId: null,
            sshPort: null
        },
        portLists: [],
        aliveTests: [],
        credentials: [],
        isValid: false,
        isInternal: this.props.isInternal,
        _exception: null,
        _loading: false,
        _saving: false,
        isExtended: false,
        resolvingDomain: false,
        mode: TargetFormMode.IP
    }
    FieldValidators = {
        name: new IsNotEmptyString(),
        comment: new IsOptionalString(),
        includedHosts: new IsNotEmptyList(),
        excludedHosts: new IsOptionalString(),
        aliveTestId: new IsNotEmptyString(),
        portListId: new IsNotEmptyString(),
        sshCredentialsId: new IsOptionalString(),
        smbCredentialsId: new IsOptionalString(),
        esxiCredentialsId: new IsOptionalString(),
        sshPort: new CustomValidationMethod(null, (value: any) => {
            const NumberValidator = new IsInteger()
            if (!this.state.fields.sshCredentialsId) {
                return true
            }
            return NumberValidator.validate(value)
        })
    }

    constructor(props: Props) {
        super(props)
        this.remoteResourceRestClient = new RemoteResourceRestApiClient()
        this.targetRestClient = new TargetRestApiClient()
        this.credentialsRestClient = new CredentialsRestApiClient()
        this.setStateAsync = Promise.promisify(this.setState)
        this.targetFormHostHandler = new TargetFormHostHandler(this.setState.bind(this), () => this.state, props.t.bind(this))
    }

    componentDidMount() {
        this.loadData()
        if (this.includedHostsRef) {
            this.includedHostsRef.onchange = (event) => {
                this.inputHostValue = event.target.value
            }
        }

        if (this.excludedHostsRef) {
            this.excludedHostsRef.onchange = (event) => {
                this.exputHostValue = event.target.value
            }
        }
    }

    loadData() {
        const portListData = this.remoteResourceRestClient.getPortLists()
        const aliveTestData = this.remoteResourceRestClient.getAliveTests()
        const credentialsData = this.credentialsRestClient.getAll()
        const {t} = this.props

        this.setState({_loading: true})
        Promise.all([portListData, aliveTestData, credentialsData])
            .then(([portListData, aliveTestData, credentialsData]) => {
                this.setState({
                    portLists: portListData,
                    aliveTests: aliveTestData,
                    credentials: [{id: null, name: t("targetForm.noCredentials")}, ...credentialsData],
                    _exception: false
                }, () => {
                    if (this.props.id) {
                        this.loadExistingTarget()
                    } else {
                        aliveTestData.length && this.setFieldsState("aliveTestId", aliveTestData.find(test => test?.name === "ICMP")?.id)

                        portListData.length && this.setFieldsState("portListId", portListData.find(portList => portList.name === "ALL IANA ASSIGNED TCP")?.id)
                    }
                })

            })
            .catch(error => {
                this.setState({
                    _exception: error
                })
            })
            .finally(() => {
                this.setState({_loading: false})
            })
    };

    loadExistingTarget = () => {
        if (!this.props.id) {
            return
        }

        this.targetRestClient.getOne(this.props.id).then((target) => {

            let includedHosts = []
            target.includedHosts.forEach((host: GSPHost) => {
                includedHosts.push(host.representation)

            })

            let excludedHosts = []
            target.excludedHosts.forEach((host: GSPHost) => {
                excludedHosts.push(host.representation)

            })

            this.setState((prevState) => {
                return {
                    fields: {
                        ...prevState.fields,
                        name: target.name,
                        comment: target.comment,
                        includedHosts,
                        excludedHosts,
                        aliveTestId: target.aliveTest.id,
                        portListId: target.portList.id,
                        sshCredentialsId: target.credentials.SSH ? target.credentials.SSH.id : "",
                        smbCredentialsId: target.credentials.SMB ? target.credentials.SMB.id : "",
                        esxiCredentialsId: target.credentials.ESXI ? target.credentials.ESXI.id : "",
                        sshPort: target.sshPort
                    },
                    mode: target.targetType
                }
            })
        })
    }

    handleOnChangeField = (event: any) => {
        const {name, value} = event.target

        this.setFieldsState(name, value)
    }

    handleModeSwitch = (newMode) => {
        this.setState({mode: newMode, fields: {...this.state.fields, includedHosts: [], excludedHosts: []}})
    }

    validateFields = async (): Promise<void> => {
        const {t} = this.props
        const {isValid, fieldValidity} = getValidationResult(
            this.state.fields,
            this.FieldValidators
        )

        if (this.state.fields.includedHosts.length === 0) {
            Snackbar.Error(t("targetForm.enterHost"))
        }
        if (isValid) {
            await this.setStateAsync({isValid})
        }

        await this.setStateAsync({fieldStatus: fieldValidity, isValid})
    }

    onFormSubmit = (event: SyntheticEvent<HTMLFormElement>) => {
        event.preventDefault()


        this.validateFields()
            .then(() => {
                this.handleFormSubmit()
            })
    }

    handleFormSubmit = () => {
        const {isValid} = this.state

        if (!isValid) {
            return
        }

        if (this.props.id) {
            this.updateTarget(this.state.fields)
        } else {
            this.createTarget(this.state.fields)
        }

    }

    handleIncludedHostsAdd = (chip: string, callback: any) => {
        if (this.state.isInternal) {
            if (IP6.isValid(chip)) {
                Snackbar.Error(t("common.targetCreate.error.ipv6NotAllowed"))
                return
            }
        }

        this.targetFormHostHandler.addHost(chip.trim(), callback)
    }

    trimIpInput = (ip: string): string => {
        ip = ip.trim()
        ip = ip.replace(/ /g, "")

        return ip
    }

    handleExcludedHostsAdd = (chip: string, callback: any) => {
        const {t} = this.props
        chip = this.trimIpInput(chip)

        if (chip === "") {
            return
        }

        if (this.state.isInternal) {
            if (IP6.isValid(chip)) {
                Snackbar.Error(t("common.targetCreate.error.ipv6NotAllowed"))
                return
            }
        }

        let ipv4 = false
        let ipv6 = false

        if (IP6.isValid(chip)) {
            ipv6 = true
        }

        if (chip.indexOf("-") > -1) {
            ipv4 = IP4.rangeIsValid(chip)
        }

        if (IP4.addressIsValid(chip)) {
            if (chip.indexOf("/") > -1) {
                chip = IP4.convertCIDRAddress(chip)
                Snackbar.Info(t("common.targetCreate.autoCorrected"))
            }
            ipv4 = true
        }

        if (ipv4) {

            let addToState = false

            try {

                if (!(chip.indexOf("-") > -1 || chip.indexOf("/") > -1)) {
                    if (IP4.isAddressIncluded(chip, this.state.fields.includedHosts)) {
                        addToState = true
                    } else {
                        Snackbar.Error(t("common.targetCreate.error.addressNotContained", {address: chip}))
                    }
                }

                if (IP4.ipInputIntersects(chip, this.state.fields.includedHosts)) {
                    addToState = true
                } else {
                    Snackbar.Error(t("common.targetCreate.error.NetNotContained", {address: chip}))
                }

                if (addToState) {

                    if (this.state.fields.excludedHosts.includes(chip)) {
                        Snackbar.Info(t("common.targetCreate.error.AlreadyExcluded"))
                        return
                    }
                    this.setState({
                        fields: {
                            ...this.state.fields,
                            excludedHosts: [...this.state.fields.excludedHosts, chip]
                        }
                    })
                }
            } catch (e) {
                if (e.message === "Same IP") {
                    Snackbar.Error(t("common.targetCreate.error.ExcludeExplizit"))
                } else {
                    Snackbar.Error(t("common.targetCreate.error.invalidInput"))
                }
            }

        } else if (ipv6) {
            if (IP6.isIntersectingList(chip, this.state.fields.includedHosts)) {
                if (IP6.isAddressIncluded(chip, this.state.fields.excludedHosts) || IP6.isAddressIncluded(chip, this.state.fields.includedHosts)) {
                    if (IP6.isAddressIncluded(chip, this.state.fields.excludedHosts)) {
                        Snackbar.Info(t("common.targetCreate.error.AlreadyExcluded"))
                    } else if (IP6.isAddressIncluded(chip, this.state.fields.includedHosts)) {
                        Snackbar.Error(t("common.targetCreate.error.ExcludeExplizit"))
                    } else {
                        Snackbar.Error(t("common.targetCreate.error.invalidInput"))
                    }

                } else {
                    this.setState({
                        fields: {
                            ...this.state.fields,
                            excludedHosts: [...this.state.fields.excludedHosts, chip]
                        }
                    })
                }

            } else {
                Snackbar.Error(t("common.targetCreate.error.NetNotContained", {address: chip}))
            }
        } else {
            Snackbar.Error(t("common.targetCreate.error.noValidAddress", {address: chip}))
        }
    }

    redirectToList = () => {
        this.props.onClose()
    }

    updateTarget = (target: any) => {
        const {t} = this.props
        this.setState({_saving: true})

        target = {
            ...target,
            isInternal: !!this.state.isInternal,
            id: this.props.id,
            credentials: {
                SMB: target.smbCredentialsId,
                SSH: target.sshCredentialsId,
                ESXI: target.esxiCredentialsId
            },
            targetType: this.state.mode
        }

        this.targetRestClient.updateEntity(target)
            .then(response => {
                this.redirectToList()
                Snackbar.Success(t("common.targetCreate.updated", {name: target.name}))
                if (this.props.onSave) {
                    this.props.onSave()
                }
                this.context.updateTargets()
            })
            .catch(error => {
                if (Value(error.type).isInList([BadRequestError])) {
                    this.setState({fieldStatus: error.fieldErrors})
                    Snackbar.Error(t("targetForm.badRequest"))
                    return
                }

                if (Value(error.type).isInList([ConflictError, BadRequestError, ServiceNotAvailableError, UnprocessableEntityError, ForbiddenError])) {
                    Snackbar.Error(error.message)
                } else if (error.type === NotAcceptableError) {
                    Snackbar.Error(t("common.error.limitReached"))
                } else {
                    this.setState({
                        _exception: error
                    })
                }
            })
            .finally(() => {
                this.setState({_saving: false})
            })
    }

    createTarget = (target: any) => {
        const {t} = this.props
        this.setState({_saving: true})

        target = {
            ...target,
            isInternal: !!this.state.isInternal,
            credentials: {
                SMB: target.smbCredentialsId,
                SSH: target.sshCredentialsId,
                ESXI: target.esxiCredentialsId
            },
            targetType: this.state.mode
        }

        this.targetRestClient.createEntity(target)
            .then(response => {
                this.redirectToList()
                Snackbar.Success(t("common.targetCreate.created", {name: target.name}))
                if (this.props.onSave) {
                    this.props.onSave()
                }
                this.context.updateTargets()
            })
            .catch(error => {
                this.setState({_saving: false})

                if (Value(error.type).isInList([BadRequestError])) {
                    this.setState({fieldStatus: error.fieldErrors})
                    Snackbar.Error(t("targetForm.badRequest"))
                    return
                }

                if (Value(error.type).isInList([ConflictError, BadRequestError, ServiceNotAvailableError, UnprocessableEntityError, ForbiddenError])) {
                    Snackbar.Error(error.message)
                } else if (error.type === NotAcceptableError) {
                    Snackbar.Error(t("common.error.limitReached"))
                } else {
                    this.setState({
                        _exception: error
                    })
                }
            })
    }

    setFieldsState = (name: string, value: string | number) => {
        this.setState(prevState => {
            let fields = prevState.fields
            fields[name] = value
            return {fields}
        })
    }

    handleIncludedHostRemove = (chip: string, index: number) => {
        const hosts = this.state.fields.includedHosts.filter(host => host !== chip)
        this.setState({
            fields: {
                ...this.state.fields,
                includedHosts: hosts
            }
        })
    }

    handleExcludedHostRemove = (chip: string, index: number) => {
        const hosts = this.state.fields.excludedHosts.filter(host => host !== chip)
        this.setState({
            fields: {
                ...this.state.fields,
                excludedHosts: hosts
            }
        })
    }

    handleIncludedHostnamesRemove = (hostname) => {
        if (isStringBlankOrWhitespace(hostname)) {
            return
        }
        const hostnames = this.state.fields.includedHosts.filter(host => host !== hostname)
        this.setState({
            fields: {
                ...this.state.fields,
                includedHosts: hostnames
            }
        })
    }


    handleIncludedHostnamesAdd = (hostname) => {
        if (isStringBlankOrWhitespace(hostname)) {
            return
        }
        this.setFieldsState("includedHosts", [...this.state.fields.includedHosts, hostname])
    }

    render() {
        const {
            includedHosts,
            excludedHosts,
            portListId,
            aliveTestId,
            sshCredentialsId,
            smbCredentialsId,
            esxiCredentialsId,
            name,
            comment,
            sshPort
        } = this.state.fields
        const {fieldStatus, _loading, mode} = this.state
        const {t} = this.props

        if (_loading) {
            return <Loader/>
        }

        return <React.Fragment>
            <div style={{maxHeight: "100vh", margin: "-4rem", display: "flex", flexDirection: "column"}}>
                <div style={{overflowY: "scroll", padding: "4rem"}}>
                    <TargetFormHeader id={this.props.id} isInternal={this.state.isInternal} mode={mode}
                                      handleModeSwitch={this.handleModeSwitch}/>

                    <Row style={{marginBottom: "2rem"}}>
                        <Col>
                            <Input onChange={this.handleOnChangeField} name={"name"}
                                   label={t("target.details.targetName")} value={name}
                                   isValid={fieldStatus?.name}/>
                        </Col>
                    </Row>
                    <Row style={{marginBottom: "2rem"}}>
                        <Col>
                            <Input onChange={this.handleOnChangeField} name={"comment"}
                                   label={t("common.description.optional")}
                                   value={comment} isValid={fieldStatus?.comment}/>
                        </Col>
                    </Row>

                    {mode === TargetFormMode.IP && <IncludedAddressInputArea isInternal={this.state.isInternal}
                                                                             handleIncludedHostRemove={this.handleIncludedHostRemove}
                                                                             handleIncludedHostsAdd={this.handleIncludedHostsAdd}
                                                                             includedHosts={includedHosts}
                                                                             resolvingDomain={this.state.resolvingDomain}
                                                                             inputRef={(ref) => this.includedHostsRef = ref}
                                                                             isValid={fieldStatus?.includedHosts}
                    />}

                    {mode === TargetFormMode.HOSTNAME &&
                    <HostnameInputArea handleIncludedHostnamesAdd={this.handleIncludedHostnamesAdd}
                                       handleIncludedHostnamesRemove={this.handleIncludedHostnamesRemove}
                                       includedHostnames={includedHosts} inputRef={ref => this.hostnameRef = ref}
                                       isValid={fieldStatus?.includedHosts}
                    />}


                    <Row style={{marginBottom: "1rem"}}>
                        <Col>
                            <FlexRow justifyContent={"flex-end"}>
                                <TextLink onClick={(event) => {
                                    event.preventDefault()
                                    this.setState(prevState => ({isExtended: !prevState.isExtended}))
                                }}>
                                    {this.state.isExtended ? t("wizard.steps.task.extendOptions.hide") : t("wizard.steps.task.extendOptions")}
                                </TextLink>
                            </FlexRow>
                        </Col>
                    </Row>


                    <div style={{display: this.state.isExtended ? "block" : "none"}}>
                        {mode === TargetFormMode.IP && <ExcludedAddressInputArea isInternal={this.state.isInternal}
                                                                                 handleExcludedHostsAdd={this.handleExcludedHostsAdd}
                                                                                 handleExcludedHostRemove={this.handleExcludedHostRemove}
                                                                                 excludedHosts={excludedHosts}
                                                                                 inputRef={(ref) => this.excludedHostsRef = ref}/>}

                        <Row>
                            <Col>
                                <Subheadline>{t("targetform.parameter")}</Subheadline>
                            </Col>
                        </Row>
                        <Row style={{marginBottom: "2rem"}}>
                            <Col>
                                <SearchableDropdown
                                    menuPlacement="top"
                                    onChange={this.handleOnChangeField}
                                    name={"aliveTestId"}
                                    value={aliveTestId || ""}
                                    placeholder={t("common.targetCreate.selectAliveTest")}
                                    options={{
                                        data: this.state.aliveTests,
                                        valueFieldName: "id",
                                        labelFieldName: "name"
                                    }}
                                    label={t("common.aliveTest")}
                                    isValid={fieldStatus?.aliveTestId}/>
                            </Col>
                            <Col>
                                <SearchableDropdown
                                    menuPlacement="top"
                                    onChange={this.handleOnChangeField}
                                    name={"portListId"}
                                    value={portListId || ""}
                                    placeholder={t("common.targetCreate.selectPortList")}
                                    options={{data: this.state.portLists, valueFieldName: "id", labelFieldName: "name"}}
                                    label={t("common.portList")}
                                    isValid={fieldStatus?.portListId}/>
                            </Col>
                        </Row>
                        <Row style={{marginBottom: "2rem"}}>
                            <Col>
                                <SearchableDropdown
                                    menuPlacement="top"
                                    onChange={this.handleOnChangeField}
                                    name={"sshCredentialsId"}
                                    value={sshCredentialsId || null}
                                    placeholder={t("common.targetCreate.selectCredentials")}
                                    options={{
                                        data: this.state.credentials,
                                        valueFieldName: "id",
                                        labelFieldName: "name"
                                    }}
                                    label={t("common.targetCreate.credentials", {type: "SSH"})}
                                    isValid={fieldStatus?.portListId}/>
                            </Col>
                            <Col style={{alignItems: "flex-end", display: "flex"}}>
                                <Input onChange={this.handleOnChangeField} type={"text"} name={"sshPort"}
                                       label={"SSH Port"}
                                       value={sshPort || ""}
                                       isValid={fieldStatus?.sshPort}/>
                            </Col>
                        </Row>
                        <Row style={{marginBottom: "2rem"}}>
                            <Col>
                                <SearchableDropdown
                                    menuPlacement="top"
                                    onChange={this.handleOnChangeField}
                                    name={"smbCredentialsId"}
                                    value={smbCredentialsId || null}
                                    placeholder={t("common.targetCreate.selectCredentials")}
                                    options={{
                                        data: this.state.credentials,
                                        valueFieldName: "id",
                                        labelFieldName: "name"
                                    }}
                                    label={t("common.targetCreate.credentials", {type: "SMB"})}
                                    isValid={fieldStatus?.portListId}/>
                            </Col>
                            <Col>
                                <SearchableDropdown
                                    menuPlacement="top"
                                    onChange={this.handleOnChangeField}
                                    name={"esxiCredentialsId"}
                                    value={esxiCredentialsId || null}
                                    placeholder={t("common.targetCreate.selectCredentials")}
                                    options={{
                                        data: this.state.credentials,
                                        valueFieldName: "id",
                                        labelFieldName: "name"
                                    }}
                                    label={t("common.targetCreate.credentials", {type: "ESXi"})}
                                    isValid={fieldStatus?.portListId}/>
                            </Col>
                        </Row>
                    </div>
                </div>

                <Row>
                    <Col>
                        <div style={{
                            justifyContent: "space-between",
                            display: "flex",
                            paddingLeft: "4rem",
                            paddingRight: "4rem"
                        }}>
                            <AbortButton name={"abort"} onClick={this.props.onClose}>{t("common.action.abort")}</AbortButton>
                            <Button name={"createTarget"} onClick={this.onFormSubmit} disabled={this.state._saving}>
                                {this.state._saving && <ButtonSpinner/>}
                                {this.props.id ? t("targetForm.update") : t("targetForm.create")} </Button>
                        </div>
                    </Col>
                </Row>
            </div>

        </React.Fragment>
    }
}

_TargetCreate.contextType = SimpleEntityContext

export const TargetCreate = compose(
    withTranslation()
)(_TargetCreate)

