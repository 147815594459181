import React from "react"
import {Snackbar} from "@greenbone/cloud-component-library"
import {TaskRestApiClient} from "../apiClients/Task/TaskRestApiClient"
import {TargetRestApiClient} from "../apiClients/Target/TargetRestApiClient"
import {RemoteResourceRestApiClient} from "../apiClients/RemoteResource/RemoteResourceRestApiClient"
import {ScheduleRestApiClient} from "../apiClients/Schedule/ScheduleRestApiClient"
import {CredentialsRestApiClient} from "../apiClients/Credentials/CredentialsRestApiClient"
import {DeleteErrors, DeleteWarnings, ForbiddenError} from "../Exceptions"
import {Value} from "../../Helper/Validator"
import {withTranslation} from "react-i18next"
import {compose} from "redux"
import {GatewayRestApiClient} from "../apiClients/Gateway/GatewayRestApiClient"


export const SimpleEntityContext = React.createContext({})

export class _SimpleEntityProvider extends React.Component {

    state = {
        tasks: [],
        credentials: [],
        schedules: [],
        gateways: [],
        scanConfigurations: [],
        portLists: [],
        aliveTests: [],
        targets: []
    }

    constructor(props) {
        super(props)
        this.TaskRestApiClient = new TaskRestApiClient()
        this.TargetRestApiClient = new TargetRestApiClient()
        this.RemoteResourceRestApiClient = new RemoteResourceRestApiClient()
        this.ScheduleRestApiClient = new ScheduleRestApiClient()
        this.CredentialsRestApiClient = new CredentialsRestApiClient()
    }

    componentDidMount() {
        this.initialLoad()
    }

    initialLoad = () => {
        this.fetchData(this.TaskRestApiClient.getAll.bind(this.TaskRestApiClient), "tasks")
        this.fetchData(this.TargetRestApiClient.getAll.bind(this.TargetRestApiClient), "targets")
        this.fetchData(this.ScheduleRestApiClient.getAll.bind(this.ScheduleRestApiClient), "schedules")
        this.fetchData(this.CredentialsRestApiClient.getAll.bind(this.CredentialsRestApiClient), "credentials")
        this.fetchData(this.RemoteResourceRestApiClient.getGateways.bind(this.RemoteResourceRestApiClient), "gateways")
        this.fetchData(this.RemoteResourceRestApiClient.getPortLists.bind(this.RemoteResourceRestApiClient), "portLists")
        this.fetchData(this.RemoteResourceRestApiClient.getScanConfigurations.bind(this.RemoteResourceRestApiClient), "scanConfigurations")
        this.fetchData(this.RemoteResourceRestApiClient.getAliveTests.bind(this.RemoteResourceRestApiClient), "aliveTests")
    }

    fetchData = (request, stateName) => {
        request()
            .then(response => {
                this.setState({[stateName]: response})
            })
            .catch(e => {
                console.log(e)
                Snackbar.Error(e)
            })
    }

    executeRequest = (request, successCallback, errorCallback) => {
        const {t} = this.props

        request
            .then(() => {
                successCallback()
            })
            .catch(error => {
                if (error.type === ForbiddenError) {
                    Snackbar.Error(error.message)
                } else if (Value(error.type).isInList(DeleteErrors)) {
                    Snackbar.Error(t("common.messages.error") + " " + error.message)
                } else if (Value(error.type).isInList(DeleteWarnings)) {
                    Snackbar.Error(t("common.error.danger") + " " + error.message)
                } else {
                    Snackbar.Error(error)
                }
            })
    }

    deleteTask = taskId => {
        this.executeRequest(this.TaskRestApiClient.deleteEntity(taskId),
            () => this.setState(prevState => ({tasks: prevState.tasks.filter(item => item.id !== taskId)})))
    }

    deleteSchedule = scheduleId => {
        this.executeRequest(this.ScheduleRestApiClient.deleteEntity(scheduleId),
            () => this.setState(prevState => ({schedules: prevState.schedules.filter(item => item.id !== scheduleId)})))
    }

    deleteCredentials = credentialId => {
        this.executeRequest(this.CredentialsRestApiClient.deleteEntity(credentialId),
            () => this.setState(prevState => ({credentials: prevState.credentials.filter(item => item.id !== credentialId)})))
    }

    deleteTarget = targetId => {
        this.executeRequest(this.TargetRestApiClient.deleteEntity(targetId),
            () => this.setState(prevState => ({targets: prevState.targets.filter(item => item.id !== targetId)})))
    }

    deleteGateway = gatewayId => {
        const restClient = new GatewayRestApiClient()


        this.executeRequest(restClient.deleteEntity(gatewayId),
            () => this.setState(prevState => ({gateways: prevState.gateways.filter(item => item.id !== gatewayId)})))
    }

    updateTasks = () => {
        this.fetchData(this.TaskRestApiClient.getAll.bind(this.TaskRestApiClient), "tasks")
    }

    updateSchedules = () => {
        this.fetchData(this.ScheduleRestApiClient.getAll.bind(this.ScheduleRestApiClient), "schedules")
    }

    updateCredentials = () => {
        this.fetchData(this.CredentialsRestApiClient.getAll.bind(this.CredentialsRestApiClient), "credentials")
    }

    updateTargets = () => {
        this.fetchData(this.TargetRestApiClient.getAll.bind(this.TargetRestApiClient), "targets")
    }

    updateGateways = () => {
        this.fetchData(this.RemoteResourceRestApiClient.getGateways.bind(this.RemoteResourceRestApiClient), "gateways")
    }

    updateAll = () => {
        this.initialLoad()
    }

    render() {

        return <SimpleEntityContext.Provider value={{
            ...this.state,
            deleteTask: this.deleteTask,
            updateTasks: this.updateTasks,
            deleteSchedule: this.deleteSchedule,
            updateSchedules: this.updateSchedules,
            deleteCredentials: this.deleteCredentials,
            updateCredentials: this.updateCredentials,
            deleteTarget: this.deleteTarget,
            updateTargets: this.updateTargets,
            deleteGateway: this.deleteGateway,
            updateGateways: this.updateGateways,
            updateAll: this.updateAll

        }}>
            {this.props.children}
        </SimpleEntityContext.Provider>
    }

}

export const SimpleEntityProvider = compose(withTranslation())(_SimpleEntityProvider)
