import {AbortButton, Button, Caption, FlexRow, SearchableSelect, Snackbar} from "@greenbone/cloud-component-library"
import Promise from "bluebird"
import React from "react"
import {withTranslation} from "react-i18next"
import {connect} from "react-redux"
import {Col, Row} from "reactstrap"
import {bindActionCreators, compose} from "redux"
import {ButtonSpinner} from "../../../Components/Animations/Button/ButtonSpinner"
import {SearchableDropdown} from "../../../Components/Input"
import Loader from "../../../Components/Loader/Loader"
import {openErrorSnackbar, openSuccessSnackbar} from "../../../Components/Snackbar/actions"
import {getValidationResult} from "../../../controller/FieldValidator"
import {CustomValidationMethod, IsNotEmptyString, IsOptionalString} from "../../../controller/FormValidators"
import {Value} from "../../../Helper/Validator"
import {RemoteResourceRestApiClient} from "../../../services/apiClients/RemoteResource/RemoteResourceRestApiClient"
import {ScheduleRestApiClient} from "../../../services/apiClients/Schedule/ScheduleRestApiClient"
import {TargetRestApiClient} from "../../../services/apiClients/Target/TargetRestApiClient"
import {TaskRestApiClient} from "../../../services/apiClients/Task/TaskRestApiClient"
import {
    BadRequestError,
    ConflictError,
    ForbiddenError,
    NotAcceptableError,
    ServiceNotAvailableError,
    UnprocessableEntityError
} from "../../../services/Exceptions"
import {Headline} from "../../../StyledComponents/Font/Font"
import {Input} from "../../../StyledComponents/Form/Input"
import {Logger} from "../../../controller/loggingController"
import {SimpleEntityContext} from "../../../services/Context/SimpleEntityContext"
import {InfoBox} from "../../Wizard/components/InfoBox"
import {ScanConfigurationInfo} from "./ScanConfigurationInfo"

type Props = {
    id: string;
    onClose: any;
    openErrorSnackbar: any;
    t: any;
}

type State = {
    fields: {
        name: string,
        comment: string,
        isInternal: boolean,
        targetId: ?string,
        scheduleId: ?string,
        scanConfigurationId: ?string,
        gatewayId: ?string,
    },
    fieldStatus: {
        name: ?boolean,
        comment: ?boolean,
        isInternal: ?boolean,
        targetId: ?boolean,
        scheduleId: ?boolean,
        scanConfigurationId: ?boolean,
        gatewayId: ?boolean
    },
    targetList: Array<any>,
    scheduleList: Array<any>,
    scanConfigurationList: Array<any>,
    isValid: boolean,
    gateways: Array<any>,
    _exception: ?Error,
    _loading: boolean,
    _saving: boolean,
};

class _TaskForm extends React.Component<Props, State> {
    taskRestApiClient: TaskRestApiClient
    targetRestApiClient: TargetRestApiClient
    remoteResourceRestApiClient: RemoteResourceRestApiClient
    scheduleRestApiClient: ScheduleRestApiClient
    setStateAsync: Promise

    state = {
        fields: {
            name: "",
            comment: "",
            isInternal: false,
            targetId: null,
            scheduleId: null,
            scanConfigurationId: null,
            gatewayId: null
        },
        fieldStatus: {
            name: null,
            comment: null,
            isInternal: true,
            targetId: null,
            scheduleId: null,
            scanConfigurationId: null,
            gatewayId: null
        },
        targetList: [],
        scheduleList: [],
        scanConfigurationList: [],
        gateways: [],
        isValid: false,
        _exception: null,
        _loading: false,
        _saving: false
    }

    FieldValidators = {
        name: new IsNotEmptyString(),
        comment: new IsOptionalString(),
        targetId: new IsNotEmptyString(),
        scheduleId: new IsOptionalString(),
        scanConfigurationId: new IsNotEmptyString(),
        gatewayId: new CustomValidationMethod("asd", (gatewayId) => {
            if (this.state.fields.isInternal) {
                if (gatewayId !== null && gatewayId !== undefined) {
                    return true
                } else {
                    return "If the task is \"internal\" a gateway must be selected"
                }
            } else {
                return true
            }


        })
    }

    constructor(props: any) {
        super(props)

        this.taskRestApiClient = new TaskRestApiClient()
        this.targetRestApiClient = new TargetRestApiClient()
        this.remoteResourceRestApiClient = new RemoteResourceRestApiClient()
        this.scheduleRestApiClient = new ScheduleRestApiClient()
        this.setStateAsync = Promise.promisify(this.setState)
    }

    componentDidMount(): void {
        this.loadData()
    }

    loadExistingTarget = () => {

        if (!this.props.id) {
            return
        }
        this.taskRestApiClient.getOne(this.props.id)
            .then(target => {

                this.setState({
                    fields: {
                        name: target.name,
                        comment: target.comment,
                        isInternal: target.isInternal,
                        scanConfigurationId: target.scanConfiguration ? target.scanConfiguration.id : null,
                        targetId: target.target ? target.target.id : null,
                        scheduleId: target.schedule ? target.schedule.id : null,
                        gatewayId: target.gatewayId ? target.gatewayId : null

                    }
                })
            })
            .catch(_exception => {
                this.setState({_exception})
            })

    }

    loadData() {
        const {t} = this.props
        const targets = this.targetRestApiClient.getAll()
        const scanConfigurations = this.remoteResourceRestApiClient.getScanConfigurations()
        const schedules = this.scheduleRestApiClient.getAll()
        const gateways = this.remoteResourceRestApiClient.getGateways()
        const tasks = this.taskRestApiClient.getAll()

        this.setState({_loading: true})
        Promise.all([targets, scanConfigurations, schedules, gateways, tasks])
            .then(async ([targets, scanConfigurations, schedules, gateways, tasks]) => {

                if (this.props.id) {
                    const oldTargets = [...targets]
                    targets = oldTargets.filter(target => target.isInternal === this.checkIfTaskIsInternal(this.props.id, tasks))
                }


                await this.setStateAsync({
                    _loading: false,
                    targetList: targets,
                    scheduleList: [{id: null, name: t("task.taskform.noSchedule")}, ...schedules],
                    scanConfigurationList: scanConfigurations,
                    gateways: gateways?.filter(gateway => gateway.apiKeyStatus === "REGISTERED")
                })

                await this.setStateAsync(prevState => ({
                    fields: {
                        ...prevState.fields,
                        targetId: this.getFirstIdFromList(prevState.targetList),
                        scheduleId: this.getFirstIdFromList(prevState.scheduleList),
                        scanConfigurationId: prevState.scanConfigurationList[0].id
                    }
                }))

                this.checkIfTargetIsInternal(this.state.fields.targetId)

                this.loadExistingTarget()
            })
            .catch(_exception => {
                this.setState({_exception, _loading: false})
            })
    }

    getFirstIdFromList = (list: Array<{ id: any }>) => {
        if (list.length === 0) {
            return null
        }
        return list[0]["id"]
    }

    setFieldsState = (name: string, value: string | number | boolean) => {
        this.setState(prevState => {
            let fields = prevState.fields
            fields[name] = value
            return {fields}
        })
    }

    handleOnChangeField = (event: any) => {
        const {name, value} = event.target


        if (name === "targetId") {
            this.checkIfTargetIsInternal(value)
        }


        this.setFieldsState(name, value)
    }

    checkIfTargetIsInternal = (id, targetList) => {
        try {

            const target = targetList ? targetList.find(target => target.id === id) : this.state.targetList.find(target => target.id === id)
            if (!target) {
                return false
            }

            this.setFieldsState("isInternal", target.isInternal)
            return target.isInternal
        } catch (e) {
            Logger.exception(e)
        }
        return false
    }

    checkIfTaskIsInternal = (id, taskList) => {
        try {


            const task = taskList ? taskList.find(task => task.id === id) : []
            if (!task) {
                return false
            }

            this.setFieldsState("isInternal", task.isInternal)
            return task.isInternal
        } catch (e) {
            Logger.exception(e)
        }
        return false
    }


    validateFields = async (): Promise<void> => {
        const {isValid, fieldValidity} = getValidationResult(this.state.fields, this.FieldValidators)

        if (isValid) {
            await this.setStateAsync({isValid})
            return
        }
        await this.setStateAsync({fieldStatus: fieldValidity, isValid})
    }

    createOrUpdateTask = () => {
        const task = {
            ...this.state.fields
        }


        this.setState({_saving: true})

        if (this.props.id) {

            this.taskRestApiClient.updateEntity({...task, id: this.props.id})
                .then(() => {
                    this.props.onClose()
                    this.context.updateTasks()
                })
                .catch(_exception => {
                    this.handleError(_exception)
                    this.setState({_saving: false})
                })
        } else {
            this.taskRestApiClient.createEntity(task)
                .then(() => {
                    this.props.onClose()
                    this.context.updateTasks()
                })
                .catch(_exception => {
                    this.handleError(_exception)
                    this.setState({_saving: false})
                })
        }

    }

    handleError = (error: any) => {
        const {t} = this.props
        if (Value(error.type).isInList([ConflictError, BadRequestError, ServiceNotAvailableError, UnprocessableEntityError, ForbiddenError])) {
            Snackbar.Error(error.message)
        } else if (error.type === NotAcceptableError) {
            Snackbar.Error(t("common.error.limitReached"))
        } else {
            this.setState({
                _exception: error
            })
        }
    }

    onFormSubmit = (event: SyntheticEvent<HTMLFormElement>) => {
        event.preventDefault()

        this.validateFields()
            .then(() => {
                if (!this.state.scanConfigurationList.includes(this.state.fields.scanConfigurationId)) {
                    const {t} = this.props
                    Snackbar.Error(t("common.error.inactive.scanConfiguration"))
                }
                if (this.state.isValid) {
                    this.createOrUpdateTask()
                }
            })
    }

    render() {

        const {_loading, _exception} = this.state
        const {t} = this.props

        if (_loading) {
            return <Loader/>
        }
        if (_exception) {
            // throw _exception;
        }

        const {name, comment, targetId, scheduleId, scanConfigurationId, isInternal, gatewayId} = this.state.fields

        return <>

            {this.props.id ?
                <Headline>{t("task.form.editTask")}</Headline>
                :
                <Headline>{t("task.form.createTask")}</Headline>
            }

            <Row style={{marginBottom: "2rem"}}>
                <Col>
                    <Input isValid={this.state.fieldStatus.name} value={name} name={"name"}
                           label={t("credentials.components.information.name")}
                           onChange={this.handleOnChangeField}/>
                </Col>
            </Row>
            <Row style={{marginBottom: "2rem"}}>
                <Col>
                    <Input isValid={this.state.fieldStatus.comment} value={comment} name={"comment"}
                           label={t("credentials.components.information.comment")}
                           onChange={this.handleOnChangeField}/>
                </Col>
            </Row>

            <Row style={{marginBottom: "2rem"}}>
                <Col>
                    <SearchableDropdown
                        placeholder={t("task.form.select")}
                        options={{data: this.state.targetList, valueFieldName: "id", labelFieldName: "name"}}
                        value={targetId || null}
                        onChange={this.handleOnChangeField}
                        name={"targetId"}
                        id={"targetName"}
                        className={"targetName"}
                        label={t("task.form.selectTarget")}/>
                </Col>
            </Row>

            <Row style={{marginBottom: "2rem"}}>
                <Col>
                    <SearchableDropdown
                        options={{data: this.state.scheduleList, valueFieldName: "id", labelFieldName: "name"}}
                        value={scheduleId || null}
                        onChange={this.handleOnChangeField}
                        name={"scheduleId"}
                        label={t("task.form.schedule")}/>
                </Col>
            </Row>

            <Row style={{marginBottom: "2rem"}}>
                <Col>
                    <SearchableDropdown
                        options={{
                            data: this.state.scanConfigurationList,
                            valueFieldName: "id",
                            labelFieldName: "name"
                        }}
                        value={scanConfigurationId || null}
                        onChange={this.handleOnChangeField}
                        name={"scanConfigurationId"}
                        label={t("task.form.ScanConfigruation")}/>
                </Col>
            </Row>

            <Row>
                <Col>
                    <InfoBox type={"info"}>
                        <ScanConfigurationInfo scanConfigurationId={this.state.fields.scanConfigurationId}/>
                    </InfoBox>
                </Col>
            </Row>

            <Row>
                <Col>
                    <InfoBox type={"tip"}>
                        <Caption>
                            {t("common.warning")}
                        </Caption>
                        <p>
                            {t("scanConfigurations.always")}
                        </p>

                    </InfoBox>
                </Col>
            </Row>

            {isInternal &&
            <Row style={{marginBottom: "1rem"}}>
                <Col>
                    <SearchableSelect options={{
                        data: this.state.gateways,
                        valueFieldName: "id",
                        labelFieldName: "location"
                    }}
                                      value={gatewayId}
                                      isValid={this.state.fieldStatus.gatewayId}
                                      onChange={this.handleOnChangeField}
                                      name={"gatewayId"}
                                      label={"Gateway"}
                    />
                </Col>
            </Row>}


            <Row>
                <Col>
                    <FlexRow justifyContent={"space-between"}>
                        <AbortButton onClick={this.props.onClose}>{t("common.action.abort")}</AbortButton>
                        <Button onClick={this.onFormSubmit}>
                            {this.state._saving && <ButtonSpinner/>}
                            {this.props.id ? t("taskForm.update") : t("taskForm.create")}
                        </Button>
                    </FlexRow>
                </Col>
            </Row>
        </>
    }

}

function mapDispatchToProps(dispatch) {
    let actions = bindActionCreators({
        openErrorSnackbar: openErrorSnackbar,
        openSuccessSnackbar: openSuccessSnackbar
    }, dispatch)
    return {...actions, dispatch}
}

function mapStateToProps(state) {
    return {}
}

_TaskForm.contextType = SimpleEntityContext

export const TaskForm = compose(
    withTranslation(),
    connect(mapStateToProps, mapDispatchToProps)
)(_TaskForm)
