import {Snackbar, TableCell, TableRow} from "@greenbone/cloud-component-library"
import {Tooltip} from "@material-ui/core"
import Switch from "@material-ui/core/Switch"
import {AssignmentInd} from "@material-ui/icons"
import React, {useContext} from "react"
import {useTranslation} from "react-i18next"
import styled, {css} from "styled-components"
import {DynamicTable} from "../../../areas/Task/components/Table/DynamicTable"
import ChangeMainUserButton from "../../../Components/ActionElements/ChangeMainUserButton"
import {TeamContext} from "./TeamContext"


const SwitchOverride = styled(Switch)`

    ${props => props.checked ? css`
        .MuiSwitch-track {
            background: ${props => props.theme.button.normal.background};
            opacity: 1 !important;
        }
    ` : css`
        .MuiSwitch-track {
            background: ${props => props.theme.button.normal.disabled};
            opacity: 1 !important;
        }
    `}

`

export function MemberList(props) {
    const {members, activateUser, deactivateUser, makeMainUser} = useContext(TeamContext)
    const {t} = useTranslation()

    const columns = [
        {
            accessor: "actions",
            Header: t("memberlist.actions")
        },
        {
            accessor: "email",
            Header: t("memberlist.email")
        },
        {
            Header: t(`Action`),
            accessor: () => ""
        }
    ]

    const handleClick = id => active => event => {
        if (active) {
            deactivateMember(id)(event)
        } else {
            activateMember(id)(event)
        }
    }

    const handleClickMakeMainUser = id => event => {
        makeMainUser(id)
            .then(response => {
                Snackbar.Success(t("Main user changed successfully"))
            })
            .catch(e => {
                if (e.status === 404) {
                    Snackbar.Error(t("User not found"))
                } else if (e.status === 403) {
                    Snackbar.Error(t("Only the main user can change the main user"))
                } else if (e.status === 422) {
                    Snackbar.Error(t("User can not be made the main user"))
                } else {
                    Snackbar.Error(e.toString())
                }
            })
    }

    const activateMember = id => event => {
        activateUser(id)
            .then(response => {
                Snackbar.Success(t("memberlist.activated"))
            })
            .catch(e => {
                if (e.status === 404) {
                    Snackbar.Error(t("memberlist.disablingYourOwn"))
                } else {
                    Snackbar.Error(e.toString())
                }
            })
    }

    const deactivateMember = id => event => {
        deactivateUser(id)
            .then(response => {
                Snackbar.Success(t("memberlist.deactivated"))
            })
            .catch(e => {
                if (e.status === 404 || e.status === 422) {
                    Snackbar.Error(t("memberlist.disablingYourOwn"))
                } else {

                    Snackbar.Error(e.toString())
                }
            })
    }

    const generator = ({row, getValue, dataset}) => {

        return <TableRow {...row.getRowProps()}>
            <TableCell style={{width: "2rem"}}>
                {!dataset?.adminManagedUser &&
                    <SwitchOverride
                        onClick={handleClick(dataset.userId)(dataset.active)}
                        checked={dataset.active}
                        color={"default"}
                        inputProps={{"aria-label": "Activate or deactivate user"}}
                        disabled={dataset?.isAdminManagedUser}
                    />}
            </TableCell>
            <TableCell>
                {dataset?.mainUser && <Tooltip title={t("Main user")}><AssignmentInd/></Tooltip>}
                {dataset.email}
                {dataset?.adminManagedUser && <>
                    {t("Managed Security Full Access")}
                </>}
            </TableCell>
            <TableCell>
                {
                    (!dataset?.mainUser && !dataset?.adminManagedUser) &&
                    <ChangeMainUserButton
                        changeMainUser={handleClickMakeMainUser(dataset.userId)}
                        emailAddress={dataset.email}
                    />
                }
            </TableCell>
        </TableRow>
    }

    return <>
        <DynamicTable entryGenerator={generator}
                      searchableColumns={["email"]}
                      actions={<div></div>}
                      defaultSortBy={["email"]}
                      data={members}
                      columns={columns}
                      cacheKey={"MemberList/2020-10-01"}
        />
    </>
}
