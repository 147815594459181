//@flow

import {withTheme} from "@material-ui/core"
import React, {useEffect, useState} from "react"
import {useTranslation, withTranslation} from "react-i18next"
import {Col, Row} from "reactstrap"
import {compose} from "redux"
import {Spacer} from "../../Components/Basic/Spacer"
import ErrorBoundary from "../../Components/ErrorBoundary"
import {Headline} from "../../StyledComponents/Font/Font"
import {GroupBaseAddressInformation} from "./GroupBaseAddressInformation"
import {TerminateSubscription} from "../Subscritpion/components/TerminateSubscription"
import {Button, ButtonSpinner, FlexRow, Input, Snackbar} from "@greenbone/cloud-component-library"
import {SubscriptionRestApiClient} from "../Subscritpion/service/SubscriptionRestApiClient"


type Props = {
    theme: any;
    t: any;
}

const Vat = props => {
    const [vat, setVat] = useState()
    const [saving, setSaving] = useState(false)

    const {t} = useTranslation()

    useEffect(() => {
        const apiClient = new SubscriptionRestApiClient()
        apiClient.getGroupVat()
            .then(response => {
                setVat(response.vatNumber || undefined)

            })
            .catch(e => {

                console.log(e)
            })
    }, [])

    const onChange = event => {
        const value = event.target.value
        setVat(value)
    }

    const save = () => {

        const apiClient = new SubscriptionRestApiClient()

        setSaving(true)
        apiClient.setGroupVat({vatNumber: vat})
            .then(response => {
                setSaving(false)
                Snackbar.Success("Saved Successfully")
            })
            .catch(e => {
                setSaving(false)
                Snackbar.Error("Could not save vat number")
                console.log(e)
            })
    }

    return <>
        <Headline>{t("vat.headline")}</Headline>
        <Input value={vat} onChange={onChange} type="text" name={"vat"}/>
        <FlexRow justifyContent={"flex-end"}>
            <Button onClick={save}>Save <ButtonSpinner hidden={!saving}/> </Button>
        </FlexRow>

    </>
}

class _ProfilePage extends React.Component<Props> {


    render() {
        const {t} = this.props

        return <>

        <Row>
                <Col xs={12} lg={8}>
                    <ErrorBoundary>
                        <GroupBaseAddressInformation/>
                        <Spacer height={"4.38rem"}/>
                    </ErrorBoundary>
                </Col>
            </Row>
            <Row>
                <Col xs={12} lg={8}>
                    <ErrorBoundary>
                        <Vat/>
                        <Spacer height={"4.38rem"}/>
                    </ErrorBoundary>
                </Col>
            </Row>
            <Row>
                <Col>
                    <TerminateSubscription/>
                </Col>
            </Row>

        </>
    }
}

export const ProfilePage = compose(
    withTranslation(),
    withTheme
)(_ProfilePage)
