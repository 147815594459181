//@flow

import HttpStatuscode from "http-status-codes"
import i18next from "i18next"
import {BaseApiClient} from "../../services/apiClients/Base/BaseApiClient"
import {
    BadRequestError,
    ForbiddenError,
    InvalidStatusCodeError,
    NotFoundError,
    ServiceNotAvailableError
} from "../../services/Exceptions"
import {VERSION} from "../../constants"
import {UserManagementUrlProvider} from "./UserManagementUrlProvider"
import {globalInstanceStorage} from "../../../GlobalSingle"


class UserRestApiClient extends BaseApiClient {
    httpClient: any

    constructor() {
        super(new UserManagementUrlProvider())
        this.httpClient = globalInstanceStorage.getFetchClient()
    }


    /**
     * Get user information
     * @returns {Promise<*>}
     */
    async getUser(): Promise<?UserInformationDto> {
        const response = await this.httpClient.get(this.urlProvider.user())
        if (response && response.status === HttpStatuscode.OK) {
            return await JSON.parse(await response.text())
        } else if (response && response.status === HttpStatuscode.NOT_FOUND) {
            return
        }

        this.handleError(response.status)
    }

    /**
     * Get the users preferred language
     * @returns {Promise<*>}
     */
    async getLanguage(): Promise<?{ language: string }> {
        const response = await this.httpClient.get(this.urlProvider.language())
        if (response && response.status === HttpStatuscode.OK) {
            return await JSON.parse(await response.text())
        }

        this.handleError(response.status)
    }

    /**
     * Update user language
     * @returns {Promise<void>}
     */
    async setLanguage(language: string): Promise<void> {
        const response = await this.httpClient.put(this.urlProvider.language(), {language: language})
        if (response && response.status === HttpStatuscode.NO_CONTENT) {
            return
        }
        this.handleError(response.status)
    }

    /**
     * Update user information
     * @param user
     * @returns {Promise<void>}
     */
    async updateUser(user: UserUpdateDTO): Promise<void> {
        const response = await this.httpClient.put(this.urlProvider.user(), user)
        if (response && response.status === HttpStatuscode.NO_CONTENT) {
            return
        }

        this.handleError(response.status)
    }

    /**
     * Update user password
     * @param oldPassword
     * @param newPassword
     * @returns {Promise<void>}
     */
    async updatePassword(oldPassword: string, newPassword: string): Promise<void> {
        const response = await this.httpClient.put(this.urlProvider.updatePassword(), {
            newPassword: newPassword,
            oldPassword: oldPassword
        })
        if (response && response.status === HttpStatuscode.NO_CONTENT) {
            return
        }

        this.handleError(response.status)
    }

    async getUerNotificationConfig(): Promise<any> {
        const response = await this.httpClient.get(this.urlProvider.notification())
        if (response && response.status === HttpStatuscode.OK) {
            return this.parseJsonText(await response.text())
        }

        this.handleError(response.status)
    }

    async updateUerNotificationConfig(notificationConfig: UserNotificationConfig): Promise<void> {
        const response = await this.httpClient.put(this.urlProvider.notification(), notificationConfig)
        if (response && response.status === HttpStatuscode.NO_CONTENT) {
            return
        }
        if (response && response.status === HttpStatuscode.BAD_REQUEST) {
            return this.parseJsonText(await response.text())
        }
        this.handleError(response.status)
    }

    async updatePayment(paymentConfig: { cardToken: string, planId: string }): Promise<void> {
        const response = await this.httpClient.put(this.urlProvider.payment(), paymentConfig)
        if (response && response.status === HttpStatuscode.NO_CONTENT) {
            return
        }

        this.handleError(response.status)
    }

    async getInvoices(): Promise<any> {

        const response = await this.httpClient.get(this.urlProvider.invoices())
        if (response && response.status === HttpStatuscode.OK) {
            return this.parseJsonText(await response.text())
        }

        this.handleError(response.status)
    }

    /**
     * Get subscription
     * @returns {Promise<*>}
     */
    async getSubscription(): Promise<?GSPSubscription> {
        const response = await this.httpClient.get(this.urlProvider.subscription())
        if (response && response.status === HttpStatuscode.OK) {
            return this.parseJsonText(await response.text())
        }

        this.handleError(response.status)
    }

    /**
     * Cancels the current subscription
     * @param cancelReason cancel reason
     * @returns {Promise<void>}
     */
    async cancelSubscription(cancelReason: string): Promise<void> {
        const body = {
            description: cancelReason,
            guiVersion: VERSION
        }
        const response = await this.httpClient.patch(
            this.urlProvider.cancelSubscription(), body)

        if (response && response.status === HttpStatuscode.NO_CONTENT) {
            return
        }

        this.handleError(response.status)
    }

    /**
     * Switch from a free account to an enterprise one
     * @param licenceKey
     * @returns {Promise<void>}
     */
    async subscribeEnterprise(licenceKey: string): Promise<void> {
        const body = {
            licenceKey
        }

        const response = await this.httpClient.put(
            this.urlProvider.enterpriceLicence(), body)

        if (response && response.status === HttpStatuscode.NO_CONTENT) {
            return
        }

        this.handleError(response.status)
    }

    /**
     * Switch from a free plan to an invoice plan
     * @param plan
     * @returns {Promise<void>}
     */
    async subscribeInvoice(plan: string): Promise<void> {
        const body = {
            plan
        }

        const response = await this.httpClient.put(
            this.urlProvider.subscriptionInvoice(), body)

        if (response && response.status === HttpStatuscode.NO_CONTENT) {
            return
        }

        this.handleError(response.status)
    }

    /**
     * Revoke the subscription canceling
     * @returns {Promise<void>}
     */
    async revokeCancelSubscription(): Promise<void> {
        const response = await this.httpClient.put(this.urlProvider.revokeCancelSubscription())
        if (response && response.status === HttpStatuscode.NO_CONTENT) {
            return
        }

        this.handleError(response.status)
    }

    async updateCreditCardSubscription(planId: string): Promise<void> {
        const response = await this.httpClient.put(this.urlProvider.subscription(), {plan: planId})
        if (response && response.status === HttpStatuscode.NO_CONTENT) {
            return
        }
        this.handleError(response.status)
    }

    /**
     * Update alternative billing address
     * @param billingAddress
     * @returns {Promise<void>}
     */
    async updateDifferentBillingAddress(billingAddress: BillingAddressUpdateDTO): Promise<void> {
        const response = await this.httpClient.put(this.urlProvider.billingAddress(), billingAddress)
        if (response && response.status === HttpStatuscode.NO_CONTENT) {
            return
        }
        this.handleError(response.status)
    }

    /**
     * Delete alternative billing address
     * @returns {Promise<void>}
     */
    async deleteDifferentBillingAddress(): Promise<void> {
        const response = await this.httpClient.delete(this.urlProvider.billingAddress())
        if (response && response.status === HttpStatuscode.NO_CONTENT) {
            return
        }
        this.handleError(response.status)
    }

    async extendEnterpriseLicence(licence: EnterpriseLicenceExtension): Promise<void> {
        const response = await this.httpClient.put(this.urlProvider.enterpriceLicence(), licence)
        if (response && response.status === HttpStatuscode.NO_CONTENT) {
            return
        }
        this.handleError(response.status)
    }

    /**
     * Webhook check via test message
     * @param webhook
     * @returns {Promise<void>}
     */
    async webhookCheck(webhook: Webhook): Promise<void> {
        const response = await this.httpClient.post(this.urlProvider.webhookCheck(), webhook)
        if (response && response.status === HttpStatuscode.NO_CONTENT) {
            return
        }

        if (response && response.status === HttpStatuscode.BAD_REQUEST) {
            return response
        } else {
            this.handleError(response.status)
        }
    }

    handleError(code: number) {
        if (!code) {
            throw new ServiceNotAvailableError()
        }
        switch (code) {
            case HttpStatuscode.NOT_FOUND:
                throw new NotFoundError()
            case HttpStatuscode.UNPROCESSABLE_ENTITY:
                throw new BadRequestError()
            case HttpStatuscode.FORBIDDEN:
                throw new ForbiddenError(i18next.t("components.error.boundary.noPermission"))
            default:
                throw new InvalidStatusCodeError(code)
        }
    }

}

export {UserRestApiClient}
