//@flow

export const VERSION = "2024.9.0"

export const GlobalConstants = {
    MENU_OPEN: "MENU_OPEN",
    MENU_CLOSE: "MENU_CLOSE",
    SET_TITLE: "SET_TITLE",
    CONNECTION_ERROR: "CONNECTION_ERROR",
    INTERNAL_SERVER_ERROR: "INTERNAL_SERVER_ERROR",
    WELCOME_WIZARD_ACCEPTED: "WELCOME_WIZARD_ACCEPTED"

}

export const GlobalReduxActions = {
    RESET: "RESET"
}

export const SCANCONFIG_SORT_ORDER_MAP = {
    "1": 0,
    "2": 1,
    "3": 3,
    "4": 4,
    "5": 5,
    "6": 6,
    "7": 2
}

export const KC_TOKEN = "GPS_kc_token"
export const KC_REFRESH_TOKEN = "GPS_kc_refresh_token"

export const AUTO_UPDATE_TIME = parseInt(process.env.REACT_APP_AUTO_UPDATE)

export const IS_LOCAL: boolean = process.env.REACT_APP_IS_LOCAL === "true"
export const IS_DEVELOP: boolean = process.env.REACT_APP_IS_DEVELOP === "true"

let baseUrl = new URL(window.location.origin)

export let BASEURL = baseUrl.origin
export const PUBLIC_FOLDER = process.env.REACT_APP_PUBLIC_FOLDER || ""
if (IS_LOCAL) {
    //BASEURL = "https://frontend-dev.greenbone.io"
     BASEURL = "https://gcs-dev.greenbone.io"
    //  BASEURL = "https://www.blueb.one"
    // BASEURL = "https://test01.bdd.greenbone.io"
    // BASEURL = "https://gcs-dev.greenbone.io"
    //BASEURL = "https://gmsp-demo.greenbone.io";
    //BASEURL = "https://gsp-demo4.greenbone.io"
    //BASEURL = "http://localhost:5000"
}

export let KEYCLOAK_FILE: string = "ui/" + (process.env.REACT_APP_KEYCLOAK_FILE: any)
if (IS_LOCAL) {
    KEYCLOAK_FILE = (process.env.REACT_APP_KEYCLOAK_FILE: any)
}

export const GSP_DEFAULT_PLAN: string = process.env.REACT_APP_DEFAULT_PLAN || ""
export const VAT_RATE: string = process.env.REACT_APP_VAT_RATE || ""
export const SECOND: number = 1000
export const MINUTE: number = 60 * SECOND
export const HOUR: number = 60 * MINUTE
export const SENTRY_KEY = process.env.REACT_APP_SENTRY_KEY


export const PLAN_BASIC_ID = process.env.REACT_APP_PLANS_BASIC || ""
export const PLAN_ADVANCED_ID = process.env.REACT_APP_PLANS_ADVANCED || ""
export const PLAN_PREMIUM_ID = process.env.REACT_APP_PLANS_PREMIUM || ""
export const PLAN_FREE_ID = process.env.REACT_APP_PLANS_FREE_ID || ""

export const GREENBONE_IPS = process.env.REACT_APP_GREENBONE_IPS || ""

export const remBaseFontSize = 13

export const LANGUAGE_FALLBACK: string = process.env.REACT_APP_LANGUAGE_FALLBACK || ""
export const LANGUAGES: string = process.env.REACT_APP_LANGUAGES || ""

export let Language: {
    "DE": "de",
    "EN": "en",
} = {}

LANGUAGES
    .split(",")
    .forEach((item) => {
        Language[item.toUpperCase()] = item
    })

export const IS_API_MOCKED = process.env.REACT_APP_ENABLE_MSW === "true"

