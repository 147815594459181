//@flow
import * as HttpStatuscode from "http-status-codes"
import i18next from "i18next"
import {ForbiddenError, InvalidStatusCodeError} from "../../Exceptions"
import {BaseApiClient} from "../Base/BaseApiClient"
import {ReportUrlProvider} from "./ReportUrlProvider"
import {globalInstanceStorage} from "../../../../GlobalSingle"
import {BASEURL} from "../../../constants"
import { Value } from "../../../Helper/Validator"


class ReportRestApiClient extends BaseApiClient {
    httpClient: any

    constructor(refreshAuth: boolean = true) {
        super(new ReportUrlProvider())
        this.httpClient = globalInstanceStorage.getFetchClient()
    }


    /**
     * Get full report for task
     * @param reportId - report id
     * @param params
     * @returns {Promise<?GSPFullReport>}
     */
    async getReportFull(reportId: string, params: any): Promise<?GSPFullReport> {
        const url = new URL(this.urlProvider.reportFull(reportId), BASEURL);
        if (params)
            Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));


        const response = await this.httpClient.getAbsolute(url);
        if (response.status === HttpStatuscode.OK) {
            return await response.json();
        }
        if (response.status === HttpStatuscode.FORBIDDEN) {
            throw new ForbiddenError(i18next.t("components.error.boundary.noPermission"))
        }
        throw new InvalidStatusCodeError(response.status)
    }

    async getReportSmall(reportId: string): Promise<?GSPSmallReport> {
        const response = await this.httpClient.get(this.urlProvider.reportSmall(reportId))
        if (response.status === HttpStatuscode.OK) {
            return this.parseJsonText(await response.text())
        }
        if (response.status === HttpStatuscode.FORBIDDEN) {
            throw new ForbiddenError(i18next.t("components.error.boundary.noPermission"))
        }
        throw new InvalidStatusCodeError(response.status)
    }

    async getOverview(): Promise<any> {
        const response = await this.httpClient.get(this.urlProvider.reportOverview())
        if (response.status === HttpStatuscode.OK) {
            return this.parseJsonText(await response.text())
        }
        if (response.status === HttpStatuscode.FORBIDDEN) {
            throw new ForbiddenError(i18next.t("components.error.boundary.noPermission"))
        }
        throw new InvalidStatusCodeError(response.status)
    }

    async getReportsSmallByTaskId(taskId: string): Promise<Array<GSPSmallReport>> {
        const response = await this.httpClient.get(this.urlProvider.reportsSmallByTaskId(taskId))
        if (response.status === HttpStatuscode.OK) {
            return this.parseJsonText(await response.text())
        }
        if (response.status === HttpStatuscode.FORBIDDEN) {
            throw new ForbiddenError(i18next.t("components.error.boundary.noPermission"))
        }
        throw new InvalidStatusCodeError(response.status);
    }

    async getAllReportsSmall(): Promise<Array<GSPSmallReport>> {
        const response = await this.httpClient.get(this.urlProvider.reportSmallAll());
        if (response.status === HttpStatuscode.OK) {
            return this.parseJsonText(await response.text());
        }
        if (response.status === HttpStatuscode.FORBIDDEN) {
            throw new ForbiddenError(i18next.t("components.error.boundary.noPermission"))
        }
        throw new InvalidStatusCodeError(response.status);
    }

    async getSmallReportsForGraph(): Promise<Array<GraphSmallReport>> {
        const response = await this.httpClient.get(this.urlProvider.reportSmallGraph());
        if (response.status === HttpStatuscode.OK) {
            return this.parseJsonText(await response.text());
        }
        if (response.status === HttpStatuscode.FORBIDDEN) {
            throw new ForbiddenError(i18next.t("components.error.boundary.noPermission"))
        }
        throw new InvalidStatusCodeError(response.status);
    }

    async deleteReport(reportId: string): Promise<any> {
        const response = await this.httpClient.delete(this.urlProvider.report(reportId))

            if (Value(response.status).isInList([HttpStatuscode.NO_CONTENT, HttpStatuscode.OK])) {
                return
            } else if (response.status === HttpStatuscode.NOT_FOUND) {
                throw new NotFoundError(i18next.t("common.client.deleteFailed.notFound"))
            } else if (response.status === HttpStatuscode.CONFLICT) {
                throw new ConflictError(i18next.t("common.client.deleteFailed.invalidData"))
            } else if (response.status === HttpStatuscode.BAD_REQUEST) {
                const responseBody = await this.getJsonResponse(response)
                throw new BadRequestError(i18next.t("common.client.deleteFailed.invalidRequest"), responseBody?.fieldErrors)
            } else if (response.status === HttpStatuscode.FORBIDDEN) {
                throw new ForbiddenError(i18next.t("components.error.boundary.noPermission"))
            }
            throw new InvalidStatusCodeError(response.status)
    }
}

export {ReportRestApiClient};
